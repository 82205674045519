<template>
  <div class="eleDistrubTotal">
    <header class="longHeader">
      <div class="header">
        <div class="left">
          <img class="leftImg1" src="../../src/assets/LOGO.png" alt="">
          <img class="leftImg2" src="../../src/assets/headTitle.png" alt="">
        </div>
        <div class="right">
          <el-tabs v-model="activeNameTab" @tab-click="handleSelect(activeNameTab)">
            <el-tab-pane label="首页" name="1"></el-tab-pane>
            <el-tab-pane label="产品中心" name="2"></el-tab-pane>
            <el-tab-pane label="行业应用" name="3"></el-tab-pane>
            <el-tab-pane label="关于容远" name="4"></el-tab-pane>
            <el-tab-pane label="联系我们" name="5"></el-tab-pane>
          </el-tabs>
        </div>
      </div>

    </header>

    <main class="main">
      <!-- 轮播图 -->
      <div class="trytry11" ref="carouseltry" @mouseenter="stopCarousel" :style="carouselStyle"
        @mouseleave="startCarousel">
        <div class="background11" v-for="(item, index) in newimg" :key="index" :style="{
          backgroundImage: `url(${item})`,
          transform: `translateX(${(index - currentIndex22) * 100}%)`, transition: 'transform 0.5s ease'
        }">
          <div class="iconleft" @click="turnleft()">

          </div>
          <div class="iconright" @click="turnright()">

          </div>
          <div class="turnbuttom">
            <span v-for="(item, index) in images" :key="index" class="litlecircle"
              :class="{ 'isActiveHover': index === currentIndex22 }" @click="currentIndex22 = index">1</span>
          </div>

        </div>
      </div>
      <!-- 轮播图结束 -->
      <!-- 宽度1200 -->
      <div class="bodys">
        <!-- 产品中心 -->
        <div v-if="!isDetailViews">
          <img class="bodyImg" src="../../src/assets/home_title_product center.png" alt="">
          <div>
            <el-tabs v-model="activeName" @tab-click="handleClick" class="custom-tabs">
              <el-tab-pane label="全部" name="1">
                <div class="productAll">
                  <div class="productAllItem" :style="getMarginStyle(index)"
                    :class="{ 'active': activeImgIndex === index }" v-for="(item,index) in productAllItemImg"
                    @mouseenter="setActiveIndex(index)" @mouseleave="setActiveIndex(null)"
                    @click="setActiveIndex(index, 'click')" :key="index">
                    <div class="productImg">
                      <img :src="item.Src" alt="图片加载中" class="responsive-image">
                    </div>
                    <div class="productTitle">{{ item.title }}</div>
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="无人机探测与反制" name="2">
                <div class="productAll">
                  <div class="productAllItem" :style="getMarginStyle(index)"
                    :class="{ 'active': activeImgIndex === index }" v-for="(item, index) in productAllItemImg"
                    v-if="index <5" @mouseenter="setActiveIndex(index)" @mouseleave="setActiveIndex(null)"
                    @click="setActiveIndex(index, 'click')" :key="index">
                    <div class="productImg">
                      <img :src="item.Src" alt="图片加载中" class="responsive-image">
                    </div>
                    <div class="productTitle">{{ item.title }}</div>
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="智能管控" name="3">
                <div class="productAll">
                  <div class="productAllItem" :style="getMarginStyle(index)"
                    :class="{ 'active': activeImgIndex === index }" v-for="(item, index) in productAllItemImg"
                    v-if="index>5&&index<9" @mouseenter="setActiveIndex(index)" @mouseleave="setActiveIndex(null)"
                    @click="setActiveIndex(index,'click')" :key="index">
                    <div class="productImg">
                      <img :src="item.Src" alt="图片加载中" class="responsive-image">
                    </div>
                    <div class="productTitle">{{ item.title }}</div>
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="智能识别及处理" name="4">
                <div class="productAll">
                  <div class="productAllItem" :style="getMarginStyle(index)"
                    :class="{ 'active': activeImgIndex === index }" v-for="(item, index) in productAllItemImg"
                    v-if="index==9" @mouseenter="setActiveIndex(index)" @mouseleave="setActiveIndex(null)"
                    @click="setActiveIndex(index, 'click')" :key="index">
                    <div class="productImg">
                      <img :src="item.Src" alt="图片加载中" class="responsive-image">
                    </div>
                    <div class="productTitle">{{ item.title }}</div>
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="加固信息产品" name="5">
                <div class="productAll">
                  <div class="productAllItem" :style="getMarginStyle(index)"
                    :class="{ 'active': activeImgIndex === index }" v-for="(item, index) in productAllItemImg"
                    v-if="index == 10" @mouseenter="setActiveIndex(index)" @mouseleave="setActiveIndex(null)"
                    @click="setActiveIndex(index, 'click')" :key="index">
                    <div class="productImg">
                      <img :src="item.Src" alt="图片加载中" class="responsive-image">
                    </div>
                    <div class="productTitle">{{ item.title }}</div>
                  </div>
                </div>
              </el-tab-pane>
            </el-tabs>
            <!-- <productCenter :airplantData="airplantDataList[clickActiveImgIndex]"></productCenter> -->
          </div>

        </div>

        <div v-if="isDetailViews">
          <productCenter :airplantData="airplantDataList[clickActiveImgIndex]"></productCenter>
        </div>


      </div>
      <!-- 宽度1920 -->
      <!-- 应用+其他产品部分 -->
      <div v-if="isDetailViews" class="applicationandOther">
        <div class="applicationandOtherImg">
          <img class="andOtherImg" :src="airplantDataList[currentPart].butoomSrc" alt="">
        </div>
      </div>
      <div v-if="clickActiveImgIndex==8" class="single9Img">
        <div class="Part9"></div>
      </div>
      <!-- 其它产品 -->
      <div v-if="isDetailViews" class="productParams">
        <div class="productParamsTitle">
          <img style="margin-right: 16px;" src="../../src/assets/paramsIcom.png" alt="">
          <span class="carTitle"> 其他产品</span>
        </div>
        <img v-if="clickActiveImgIndex>0" class="leftIcon" src="../../src/assets/systemFeatureIcon/left.png" alt=""
          @click="moveLeft">
        <img v-if="clickActiveImgIndex == 0" class="leftIcon" src="../../src/assets/systemFeatureIcon/leftGray.png"
          alt="" @click="moveLeft">

        <img v-if="clickActiveImgIndex < productAllItemImg.length-3" class="rightIcon"
          src="../../src/assets/systemFeatureIcon/right.png" alt="" @click="moveRight">
        <img v-else class="rightIcon" src="../../src/assets/systemFeatureIcon/rightGray.png" alt=""
          @click="moveRight">
        <div class="productAlPart">
          <div class="productAll" :style="{ transform: `translateX(-${clickActiveImgIndex * itemWidth}%)` }">
            <div class="productAllItem" v-for="(item, index) in productAllItemImg" :key="index"
              @mouseenter="setActiveIndex(index)" @mouseleave="setActiveIndex(null)" @click="clickActiveIndex(index)"
              :class="{ 'active': activeImgIndex === index, 'clickActive': clickActiveImgIndex === index }">
              <div class="productImg">
                <img :src="item.Src" alt="图片加载中" class="responsive-image">
              </div>
              <div class="productTitle">{{ item.title }}</div>
            </div>
          </div>
        </div>
      </div>
    </main>

    <footer>
      <div class="footerPart">
        <div class="footleft">
          <div class="leftertop">
            <ul>
              <li s>产品中心</li>
              <li>无人机探测与反制产品</li>
              <li>智能管控产品</li>
              <li>智能识别及处理产品</li>
              <li>加固信息产品</li>
            </ul>
            <ul>
              <li>行业应用</li>
              <li>单兵训练</li>
              <li>海上防导</li>
              <li>智慧哨塔</li>
              <li>边境巡逻</li>
            </ul>
            <ul>
              <li>联系我们</li>
              <li>联系我们</li>
              <li>商务合作</li>
            </ul>
            <ul>
              <li>关于容远</li>
              <li>公司简介</li>
              <li>荣誉资质</li>
              <li>发展历程</li>
            </ul>
          </div>
          <div class="footleftbottom">
            © 成都国科容远科技有限公司版权所有 <a style="color: white; text-decoration: none;"
              href="https://beian.miit.gov.cn/">蜀ICP备2024107035号-1</a>
          </div>
          <!-- <span>© 成都国科容远科技有限公司版权所有 蜀ICP备111111114号-1 技术支持 公安备案号：川公网安备12345678998</span> -->
        </div>
        <div class="footright">
          <div class="footNumber">
            <div class="footNumberspan1">
              <span>电话</span> <span class="citymargin">成都</span> <span>028-86248068</span>
            </div>
            <div class="footNumberspan2">
              <span class="citymargin">北京</span> <span>010-84566771</span>
            </div>
          </div>
          <div class="footCode">
            <!-- <img class="QRcode1" src="../../src/assets/qrCode.png" alt=""> -->
            <!-- <img class="QRcode2" src="../../src/assets/qrCode.png" alt=""> -->
            <!-- <router-link to="/twoView">Go to Two View</router-link> -->
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import productCenter from '@/components/prorCenPublic.vue'
export default {
  // name: 'HelloWorld',
  components: {
    productCenter,
  },
  data() {
    return {
      activeNameTab:'2',  //导航栏
      isDetailViews:false,
      itemWidth: 9.1 ,// 每个 item 的宽度（根据你的 CSS 设置）
      clickActiveImgIndex:0,
      activeImgIndex:1,
      currentPart:1,   //无人机探测与反制系统中五个小部分中的哪一个, 控制底部大图和5个tab
      titleSelect:1,
      activeName: '1', //当前所在tab11个产品
      currentIndex22: 0,
      getPageWidthTimer: '',//监听页面宽度计时器
      carouselheight: '',  //轮播高度
      righttimer: '',
      animTimer: '',
      centerCurrentIndex: 0,
      activeIndex: '1',
      images: [
        require('../../src/assets/banner_bianxie.png'),
        require('../../src/assets/banner_beifu.png'),
        require('../../src/assets/banner_pika.png'),
        require('../../src/assets/banner_control.png'),
      ],
      aboutRongYuan: [
        {
          src: require('../../src/assets/home_icon_aboutus1.png'),
          title: '诚信·尊重'
        },
        {
          src: require('../../src/assets/home_icon_aboutus2.png'),
          title: '责任·担当'
        },
        {
          src: require('../../src/assets/home_icon_aboutus3.png'),
          title: '创新·开放'
        },
        {
          src: require('../../src/assets/home_icon_aboutus4.png'),
          title: '自信·乐观'
        },

      ],
      airplantDataList: [
        {
          topSrc: require('../../src/assets/productTopImg/carProduct.png'),
          title1: '车载式无人机探测与反制系统',
          message1: '基于民用车载底盘，集雷达探测、全向电磁侦测、光电识别跟踪、通信压制干扰、导航欺骗诱捕设备高度集成化，构建具有协同机制的探测系统，利用交互印证、协同定位等手段，从不同空间维度进行目标的快速探测感知、综合识别和稳定跟踪，实现对无人机目标的侦干一体化。系统可适应高温严寒、高湿热高盐雾的野外、作战区等恶劣环境条件，满足多种应用需求。',
          title2: '产品参数',
          title3: '系统特点',
          message2: '整车尺寸：5820mm（长）x1974mm（宽）x3490mm（高）（民品车载系统）',
          message3: '设备重量：3900kg（民用车载系统）',
          message4: '压制距离：6km ~ 8km（干通比10：1）',
          message5: '诱骗距离：6km ~ 8km',
          butoomSrc: require('../../src/assets/productButtomImg/application.png'),
        }, {
          topSrc: require('../../src/assets/productTopImg/productcenter_BX_Image.png'),
          title1: '便携式无人机探测与反制系统',
          message1: '便携式无人机探测与反制系统是一款智能化、小型化、模块化的反无人机装备，采用雷达、光学、无线电信号三种融合侦察手段，可对防御区域内的低空无人机目标实现快速搜索、高效识别、自动捕获，利用信号压制、导航干扰等手段，对无人机目标的控制链路、图传链路进行侦察、干扰，使无人机在进入受控范围后失去控制，有效迫降或返航。',
          title2: '产品参数',
          title3: '系统特点',
          message2: '尺寸大小：808mm（长）x410mm（宽）x800mm（高）',
          message3: '设备重量：≤52kg（不包含支撑架、电池）',
          message4: '压制距离：1km ~ 2km（干通比10：1）',
          message5: '定向距离：≥1km',
          butoomSrc: require('../../src/assets/productButtomImg/productcenter_apply_BX.png'),
          sysFeature: [{
            FeatureIcon: require('../../src/assets/systemFeatureIcon/productcenter_icon_GJC.png'),
            iconWorld: '高集成',
            produceMessage: '集探测反制于一体，通过电磁信号侦测目标，使用电磁进行压制与干扰。'
          },
          {
            FeatureIcon: require('../../src/assets/systemFeatureIcon/productcenter_icon_BXKC.png'),
            iconWorld: '便携快拆',
            produceMessage: '采用快速拆卸、组装的接口设计，两个拉杆箱即可收纳所有设备，便于转移。'
          }, {
            FeatureIcon: require('../../src/assets/systemFeatureIcon/productcenter_icon_ZNH.png'),
            iconWorld: '智能化',
            produceMessage: '可自动识别目标类型、判断威胁等级，生成反制方案。'
          }, {
            FeatureIcon: require('../../src/assets/systemFeatureIcon/productcenter_icon_ BXCZ2.png'),
            iconWorld: '便捷操作',
            produceMessage: '使用操作平板，实时显示电磁信号目标方位，完成目标切换，设备转向等。'
          }, {
            FeatureIcon: require('../../src/assets/systemFeatureIcon/productcenter_icon_ MKH.png'),
            iconWorld: '模块化',
            produceMessage: '各分系统可独立部署工作，可根据不同应用场景需求灵活配置、快速机动。'
            }, {
              FeatureIcon: require('../../src/assets/systemFeatureIcon/productcenter_icon_DGH.png'),
            iconWorld: '低功耗',
            produceMessage: '设备峰值功率小于800W，在有限  的能源下保证了更长的工作时间。'
            },]
        }, {
          topSrc: require('../../src/assets/productTopImg/productcenter_BF_Image.png'),
          title1: '背负式探测反制系统',
          message1: '背负式无人机探测与反制系统采用探测与反制一体化设计，是集成度高、方便携带、锂电池内置的单人背负式无人机探测与反制设备。手持终端不但可以实时显示背负设备的电磁信号探测结果，还可快速开启无人机导航和图传信号压制功能，能够在行进中实时进行探测与反制作业。',
          title2: '产品参数',
          title3: '系统特点',
          message2: '尺寸大小：370mm（长）x340mm（宽）x460mm（高）',
          message3: '设备重量：14kg（背包）11kg（全向电侦）',
          message4: '反制距离：≥1km ~ 2km（干通比10：1）',
          message5: '侦测距离：≥2km ~ 5km',
          butoomSrc: require('../../src/assets/productButtomImg/productcenter_apply_BF.png'),
          sysFeature: [{
            FeatureIcon: require('../../src/assets/systemFeatureIcon/productcenter_icon_GJC.png'),
            iconWorld: '空地一体化侦察',
            produceMessage: '由穿越机、背负式设备、车载固定式设备组成的班组，全方位探测与反制。'
          },
          {
            FeatureIcon: require('../../src/assets/systemFeatureIcon/productcenter_icon_ MKH.png'),
            iconWorld: '模块化设计',
            produceMessage: '背负式设备、穿越机及探测与反制设备可独立作业，可按需增减设备。'
          }, {
            FeatureIcon: require('../../src/assets/systemFeatureIcon/productcenter_icon_ JDX.png'),
            iconWorld: '机动性强',
            produceMessage: '车载设备箱能实现设备自动升降及探测，后排操作台能对设备实时操控。'
          }, {
            FeatureIcon: require('../../src/assets/systemFeatureIcon/productcenter_icon_ TS.png'),
            iconWorld: '探索能力强',
            produceMessage: '采用无源多波段探测，多数据融合处理，探测概率高、虚警率低。'
          }, {
            FeatureIcon: require('../../src/assets/systemFeatureIcon/productcenter_icon_ SMS.png'),
            iconWorld: '干扰手段多样化',
            produceMessage: '转台实现全向 360 °覆盖以及背负补盲，全部重点频段干扰包括国内外无人机。'
          }, {
            FeatureIcon: require('../../src/assets/systemFeatureIcon/productcenter_icon_MBBH.png'),
            iconWorld: '高效目标捕获',
            produceMessage: '多种探测感知数据融合，获取目标信息，生成反制策略。'
          },]
        }, {
          topSrc: require('../../src/assets/productTopImg/productcenter_XBF_Image.png'),
          title1: '背负式探测与反制一体化设备',
          message1: '适配先进的轻量化、远距离探测设备，采用探测与反制一体化设计，是一款集成度高、方便携带、锂电池内置的单人背负式无人机探测与反制设备。手持终端不但可以实时显示背负设备的电磁信号探测结果，还可快速开启无人机导航和图传信号压制功能，能够在行进中实时进行探测与反制作业。',
          title2: '产品参数',
          title3:'系统特点',
          message2: '尺寸大小：330mm( 长 )*340mm( 宽 )*450mm( 高 )   946mm( 展开高 )',
          message3: '设备重量：≤10kg ( 背负 )      ≤5kg ( 手持 ',
          message4: '反制距离：≥1.5km',
          message5: '诱骗频段：GPS L1、北斗 B1、伽利略 E1、GLONASS  L1',
          butoomSrc: require('../../src/assets/productButtomImg/productcenter_apply_ XBF.png'),
          sysFeature: [{
            FeatureIcon: require('../../src/assets/systemFeatureIcon/productcenter_icon_ RXH.png'),
            iconWorld: '人性化',
            produceMessage: '符合人体工学的背负设计，可长时间背负作业。'
          },
          {
            FeatureIcon: require('../../src/assets/systemFeatureIcon/productcenter_icon_ SMS.png'),
            iconWorld: '双模式',
            produceMessage: '固定、移动两种作业模式。单兵、联网两种作业模式。'
          }, {
            FeatureIcon: require('../../src/assets/systemFeatureIcon/productcenter_icon_DGH.png'),
            iconWorld: '长续航',
            produceMessage: '可插拔大容量高密度锂电池，满足全天候侦测、反制作业。'
            },
          ]
        }, {
          topSrc: require('../../src/assets/productTopImg/productcenter_PK_Image.png'),
          title1: '低空车载防御平台（轻型机动车）',
          message1: '轻型机动版无人机侦察与反制车，是一款针对通用皮卡底盘（我司选用长城炮柴油版）开发的多手段、高集成、可机动部署的一整套反无人机装备体系，集成便携探测与反制设备、背负反制设备、背负FPV反制设备构成三种协同部署低空防御体系，可对防御区域内的低空无人机目标实现快速搜索、高效识别，并在车内或远程操作人员的确定下对目标进行电磁干扰压制、导航诱骗夺控、FPV拦截三种组合防御。',
          title2: '产品参数',
          title3: '系统特点',
          message2: '整车尺寸：5653*1883*2300（mm）长城炮皮卡柴油版',
          message3: '侦测距离：5km',
          message4: '侦测频段：300MHz~6GHz',
          message5: '诱骗距离：1km',
          butoomSrc: require('../../src/assets/productButtomImg/productcenter_apply_ PK.png'),
          sysFeature: [{
            FeatureIcon: require('../../src/assets/systemFeatureIcon/productcenter_icon_GJC.png'),
            iconWorld: '空地一体化侦察',
            produceMessage: '由穿越机、背负式设备、车载固定式设备组成的班组，全方位探测与反制。'
          },
          {
            FeatureIcon: require('../../src/assets/systemFeatureIcon/productcenter_icon_ MKH2.png'),
            iconWorld: '模块化设计',
            produceMessage: '背负式设备、穿越机及探测与反制设备可独立作业，可按需增减设备。'
          }, {
            FeatureIcon: require('../../src/assets/systemFeatureIcon/productcenter_icon_ JDX.png'),
            iconWorld: '机动性强',
            produceMessage: '车载设备箱能实现设备自动升降及探测，后排操作台能对设备实时操控。'
          }, {
            FeatureIcon: require('../../src/assets/systemFeatureIcon/productcenter_icon_ TS.png'),
            iconWorld: '探索能力强',
            produceMessage: '采用无源多波段探测，多数据融合处理，探测概率高、虚警率低。'
          }, {
            FeatureIcon: require('../../src/assets/systemFeatureIcon/productcenter_icon_ SMS.png'),
            iconWorld: '干扰手段多样化',
            produceMessage: '转台实现全向 360 °覆盖以及背负补盲，全部重点频段干扰包括国内外无人机。'
          }, {
            FeatureIcon: require('../../src/assets/systemFeatureIcon/productcenter_icon_MBBH.png'),
            iconWorld: '高效目标捕获',
            produceMessage: '多种探测感知数据融合，获取目标信息，生成反制策略。'
          },]
        },

// 增

        {
          topSrc: require('../../src/assets/productTopImg/productcenter_FT_Image.png'),
          title1: '飞腾主控系列——JKFT2-1  19英寸导冷综合控制主机',
          message1: 'JKFT2系列是国产化平台系列产品，是一款无扇低功耗嵌入式19英寸整机，板载FT2000/4或D2000/8国产飞腾处理器，整机支持国产银河麒麟V10/V4、Linux、Win10等操作系统。整机结构加固，外形尺寸美观，机壳采用铝合金机加成形。结构坚固、无风扇设计，外壳增加纹路有利散热，具有忧良的密封防尘、散热与抗振性能，广泛应用于车载多设备载荷接入智能控制领域，实现设备一键化控制和状态监控。',
          title2: '产品参数',
          title3: '系统特点',
          message2: '处理器：国产飞腾 FT2000/4   D2000/8',
          message3: '内部存储：128GB、256GB、512GB',
          message4: '工作温度：-40℃～+55℃',
          message5: '功耗：35W',
          sysFeature: [{
            FeatureIcon: require('../../src/assets/systemFeatureIcon/productcenter_icon_ GCPT.png'),
            iconWorld: '国产平台',
            produceMessage: '采用全国产飞腾或瑞芯微主控芯片和外围电路。'
          },
          {
            FeatureIcon: require('../../src/assets/systemFeatureIcon/productcenter_icon_ MKH.png'),
            iconWorld: '模块化',
            produceMessage: '整机内部采用模块化设计，提高产品平均无故障时间。'
          }, {
            FeatureIcon: require('../../src/assets/systemFeatureIcon/productcenter_icon_ wfs.png'),
            iconWorld: '全密封无风扇设计',
            produceMessage: '全密封无风扇箱体设计，整机运行状态无噪音。'
          }, {
            FeatureIcon: require('../../src/assets/systemFeatureIcon/wendu.png'),
            iconWorld: '宽温工作',
            produceMessage: '在-40°C～+55°C稳定工作，满足整机的高低温适应性。'
          }, {
            FeatureIcon: require('../../src/assets/systemFeatureIcon/productcenter_icon_DCJR.png'),
            iconWorld: '电磁兼容性设计',
            produceMessage: '整机导电胶条密封，采用不锈钢带屏蔽的航插件，具有陆军7项的通过能力。'
          },]
        }, {
          topSrc: require('../../src/assets/productTopImg/productcenter_RX_Image.png'),
          title1: '瑞芯微主控系列——JKRK2  19英寸导冷综合控制主机',
          message1: 'JKRK2系列是国产化平台系列产品，是一款无扇低功耗嵌入式19英寸整机，板载RK3588国产ARM处理器，整机支持Linux操作系统。整机结构加固，外形尺寸美观，机壳采用铝合金机加成形。结构坚固、无风扇设计，外壳增加纹路有利散热，具有忧良的密封防尘、散热与抗振性能，可广泛应用于车载多设备载荷接入智能控制领域，实现设备一键化控制和状态监控。',
          title2: '产品参数',
          title3: '系统特点',
          message2: '处理器：国产RK3588平台',
          message3: '内部存储：内存8GB 固态存储64G',
          message4: '工作温度：-40℃～+55℃',
          message5: '功耗：25W',
          sysFeature: [{
            FeatureIcon: require('../../src/assets/systemFeatureIcon/productcenter_icon_ GCPT.png'),
            iconWorld: '国产平台',
            produceMessage: '采用全国产RK3588平台，满足客户快速选型需求。'
          },
          {
            FeatureIcon: require('../../src/assets/systemFeatureIcon/productcenter_icon_ MKH.png'),
            iconWorld: '模块化',
            produceMessage: '整机内部采用模块化设计，提高产品平均无故障时间。'
          }, {
            FeatureIcon: require('../../src/assets/systemFeatureIcon/productcenter_icon_ wfs.png'),
            iconWorld: '全密封无风扇设计',
            produceMessage: '全密封无风扇设计，整机无噪音，防尘提高整机使用寿命。'
          }, {
            FeatureIcon: require('../../src/assets/systemFeatureIcon/wendu.png'),
            iconWorld: '宽温工作 ',
            produceMessage: '在-40°C～+55°C稳定工作，满足整机的高低温适应性。'
          }, {
            FeatureIcon: require('../../src/assets/systemFeatureIcon/productcenter_icon_DCJR.png'),
            iconWorld: '电磁兼容性设计',
            produceMessage: '整机全体用导电胶条密封，输入输出接口采用不锈钢带屏蔽的航插件。'
          },]
        }, {
          topSrc: require('../../src/assets/productTopImg/productcenter_YTJ_Image.png'),
          title1: '一体化触控屏（远程控制单元）',
          message1: '远程控制单元是国产化平台系列产品，是一款低功耗嵌入式10.1英寸触控显示一体机，板载RK3588国产ARM处理器，整机支持Linux操作系统。整机结构加固，外形尺寸美观，机壳采用铝合金机加成形。结构坚固，外壳增加纹路有利散热，具有忧良的密封防尘、散热与抗振性能，可广泛应用于车载控制终端，实现受控设备的一键化控制和状态监控。',
          title2: '产品参数',
          title3: '系统特点',
          message2: '处理器：国产RK3588平台',
          message3: '内部存储：4GB/8GB',
          message4: '工作温度：-20℃~+55℃（-40℃~+55℃(可扩展))',
          message5: '功耗：9W',
          sysFeature: [{
            FeatureIcon: require('../../src/assets/systemFeatureIcon/productcenter_icon_ GCPT.png'),
            iconWorld: '国产平台',
            produceMessage: '采用全国产RK3588平台，便于满足客户快速选型需求。'
          },
          {
            FeatureIcon: require('../../src/assets/systemFeatureIcon/productcenter_icon_ MKH.png'),
            iconWorld: '模块化',
            produceMessage: '整机内部加固设计，电源采用模块设计。220V或宽压直流接入。'
          }, {
            FeatureIcon: require('../../src/assets/systemFeatureIcon/productcenter_icon_ wfs.png'),
            iconWorld: '全密封无风扇设计',
            produceMessage: '全密封无风扇导冷设计，运行无噪音，防尘提高使用寿命。'
          }, {
            FeatureIcon: require('../../src/assets/systemFeatureIcon/wendu.png'),
            iconWorld: '宽温工作 ',
            produceMessage: '在-40°C～+55°C稳定工作，满足整机的高低温适应性。'
          }, {
            FeatureIcon: require('../../src/assets/systemFeatureIcon/productcenter_icon_DCJR.png'),
            iconWorld: '可发展性设计',
            produceMessage: '整机具备多功能化接口，支持视频输入、输出。'
          },]
        }, {
          topSrc: require('../../src/assets/productTopImg/productcenter_LXJ_Image.png'),
          title1: '硬盘录像机',
          message1: ' RK3588硬盘录像机是国产化平台系列产品，搭载RK3588国产处理器，支持国产麒麟V10，liunx等操作系统，整体结构加固，外形尺寸美观，机壳采用铝合金机加成形，结构坚固，外壳有散热孔，有利于散热。可广泛用于车载设备，将摄像机拍摄的视频信号进行编码、存储和回放，同时支持多种网络协议和云服务，方便用户进行远程监控和管理。',
          title2: '产品参数',
          title3: '系统特点',
          message2: '处理器：国产RK3588平台',
          message3: '内部存储：内存8GB  固态存储 512G',
          message4: '工作温度：-10℃～+55℃（-40℃～+55℃（可扩展））',
          message5: '功耗：15W',
          sysFeature: [{
            FeatureIcon: require('../../src/assets/systemFeatureIcon/productcenter_icon_ GCPT.png'),
            iconWorld: '国产平台',
            produceMessage: '采用国产RK3588平台，搭配多种可选规格、性能控制模块。'
          },
          {
            FeatureIcon: require('../../src/assets/systemFeatureIcon/productcenter_icon_ MKH.png'),
            iconWorld: '模块化',
            produceMessage: '整机内部加固设计，电源采用模块设计，可宽压直流(17-48V)接入'
          },{
            FeatureIcon: require('../../src/assets/systemFeatureIcon/productcenter_icon_DCJR.png'),
            iconWorld: '可开发性设计',
            produceMessage: '整机具备多功能化接口，支持视频输入、输出，内置交换机模块。'
          },]
        }, {
          topSrc: require('../../src/assets/productTopImg/RK3588.png'),
          title1: 'RK3588板卡',
          message1: 'RK3588是一颗高性能、 低功耗的应用处理器芯片， 由 4 个 Cortex-A76 和 4 个 Cortex-A55 及独立的NEON 协处理器集成，适用于 ARM PC、边缘计算、 个人移动互联网设备和其它多媒体产品。内置了多种功能强大的嵌入式硬件引擎，为高端应用提供了优异的性能， 支持 8K@60fps 的H.265 和 VP9 解码器、 8k@30fps 的 H.264 解码器和 4K@60fps 的 AV1 解码器；还支持 8K@30fps 的 H.264和 H.265 编码器，高质量的 JPEG 编码器/解码器，专门的图像预处理器和后处理器。',
          title2: '产品参数',
          title3: '系统特点',
          message2: '尺寸：130mmx75mm',
          message3: '内部存储：8GB',
          message4: '工作温度：-40℃~55℃EMMC：',
          message5: 'EMMC：64GB/128GB',
          sysFeature: [{
            FeatureIcon: require('../../src/assets/systemFeatureIcon/productcenter_icon_ SSXN.png'),
            iconWorld: '显示性能',
            produceMessage: '带有 MMU 的特殊 2D硬件引擎将最大限度地提高显示性能'
          },
          {
            FeatureIcon: require('../../src/assets/systemFeatureIcon/productcenter_icon_ JJS.png'),
            iconWorld: '算法加速器',
            produceMessage: '引入了新一代完全基于硬件的最大 48M 像素 ISP。'
          }, {
            FeatureIcon: require('../../src/assets/systemFeatureIcon/productcenter_icon_ GZYY.png'),
            iconWorld: '支持各种应用',
            produceMessage: '具有高性能的 4 通道外部存储器接口，还提供了一套完整的外设接口'
          },]
        }, {
          topSrc: require('../../src/assets/productTopImg/solidComputer.png'),
          title1: '加固信息产品（加固笔记本）',
          message1: 'JNB-1502是一款能适应恶劣工作环境的具有坚固性、高稳定性和可靠性的特种笔记本电脑。具有防水、防尘、防振、防跌落、宽温和携带方便等特点，可满足特殊行业在不同应用场景下的计算需求。',
          title2: '产品参数',
          title3: '系统特点',
          message2: '尺寸：15.6寸液晶显示屏',
          message3: '内存：8G DDR',
          message4: '显示：15.6寸液晶显示屏',
          message5: '硬盘：4T宽温固态硬盘',
          sysFeature: [{
            FeatureIcon: require('../../src/assets/systemFeatureIcon/productcenter_icon_ KKX.png'),
            iconWorld: '可靠性',
            produceMessage: ''
          },
          {
            FeatureIcon: require('../../src/assets/systemFeatureIcon/productcenter_icon_WXX.png'),
            iconWorld: '维修性',
            produceMessage: ''
          }, {
            FeatureIcon: require('../../src/assets/systemFeatureIcon/productcenter_icon_ AQX.png'),
            iconWorld: '安全性',
            produceMessage: ''
          }, {
            FeatureIcon: require('../../src/assets/systemFeatureIcon/productcenter_icon_BZX.png'),
            iconWorld: '保障性',
            produceMessage: ''
          }, {
            FeatureIcon: require('../../src/assets/systemFeatureIcon/productcenter_icon_CSX.png'),
            iconWorld: '测试性',
            produceMessage: ''
            }, {
            FeatureIcon: require('../../src/assets/systemFeatureIcon/productcenter_icon_ hj.png'),
              iconWorld: '环境适应性',
              produceMessage: ''
            },]
        },



        
      ],
      
      form: {
        name: '',

        resource: '',

      },
      productAllItemImg: [{
        title: '车载式无人机探测与反制系统',
        isActive: true,
        Src: require('../../src/assets/productAllPart/interTurntable.png'),
      },
      {
        title: '便携式无人机探测与反制系统',
        isActive: false,
        Src: require('../../src/assets/productAllPart/portable.png'),
      },
      {
        title: '背负式无人机探测反制系统',
        isActive: false,
        Src: require('../../src/assets/productAllPart/group29.png'),
      },
      {
        title: '背负式探测与反制一体化设备',
        isActive: false,
        Src: require('../../src/assets/productAllPart/AiObject.png'),
        },
        {
          title: '低空车载防御平台',
          isActive: true,
          Src: require('../../src/assets/productAllPart/pickUp.png'),
        },
        {
          title: '飞腾主控系列',
          isActive: false,
          Src: require('../../src/assets/productAllPart/rockchip.png'),
        },
        {
          title: '瑞芯微主控系列',
          isActive: false,
          Src: require('../../src/assets/productAllPart/SWissinmicro.png'),
        },
        {
          title: '一体化触控屏',
          isActive: false,
          Src: require('../../src/assets/productAllPart/controlPanel.png'),
        }, {
          title: '硬盘录相机',
          isActive: false,
          Src: require('../../src/assets/productAllPart/DVR.png'),
        },
        {
          title: 'RK3588板卡',
          isActive: false,
          Src: require('../../src/assets/productAllPart/3588Card.png'),
        },
        {
          title: '加固笔记本',
          isActive: false,
          Src: require('../../src/assets/productAllPart/ruggedizedComputer.png'),
        },
      ],
      productchangeItemImg: [],
      
      videoSrc: '',
    }
  },
  methods: {
    moveLeft() {
      if (this.clickActiveImgIndex > 0) {
        this.clickActiveImgIndex--; // 向左移动
      }
    },
    moveRight() {
      if (this.clickActiveImgIndex < this.productAllItemImg.length - 3) {
        this.clickActiveImgIndex++; // 向右移动
      }
    },
    clickActiveIndex(index) {
      this.clickActiveImgIndex = index;
      this.currentPart = index
    },
    setActiveIndex(index, str) {
      if (str && str=='click') {
        this.isDetailViews = true
        this.clickActiveImgIndex = index
        this.currentPart=index
      }
      console.log(this.activeImgIndex, index,'setActiveIndex')
      this.activeImgIndex = index; // 更新当前活动索引
    },
    handleViewportChange(mql) {
      this.viewportWidth = window.innerWidth; // 更新视口宽度
      if (mql.matches) {
        console.log("视口宽度小于 768px");
        this.isPCport = false
        this.carouselfirstheight = 176.5
        // 在这里执行你想要的方法
      } else {
        console.log("视口宽度大于等于 768px");
        this.isPCport = true
        this.carouselfirstheight = 450
        // 在这里执行你想要的方法
      }
    },
    getMarginStyle(index) {
      if ([1, 4, 7, 10].includes(index)) {
        return { marginLeft: '2.2%', marginRight: '2.2%' };
      }
      return {};
    },
    handleClick(tab) {

      if (+tab.name == 1) {
        this.startCarousel()
      } else {
        this.stopCarousel()
        this.currentIndex22 = +tab.name - 2
      }

      switch (this.activeName) {
      case '1':
          this.productchangeItemImg=[]
          break;
        case '2':
          this.productchangeItemImg = this.productAllItemImg.slice(0, 4)
          break;
        case '3':
          this.productchangeItemImg = this.productAllItemImg.slice(5, 8)
          break;
        case '4':
          this.productchangeItemImg =[this.productAllItemImg[9]]
          break;
        case '5':
          this.productchangeItemImg = [this.productAllItemImg[10]]
          break;
    
      default:
        break;
    }  



    },
    startCarousel() {
      this.stopCarousel(); // 确保没有重复的定时器
      this.startAnimation()
    },
    stopCarousel() {
      clearInterval(this.animTimer);
    },
    turnleft() {
      const elements = document.querySelectorAll('.background11');
      elements.forEach(element => {
        element.style.transition = 'transform 0.5s ease';
      });
      // 更新索引
      this.currentIndex22--
      if (this.currentIndex22 === -1) {
        elements.forEach(element => {
          this.currentIndex22 = 4    //回到最后一张克隆图
          element.style.transition = 'none';   //取消过渡动画
          setTimeout(() => {
            //回到最后一张真图片
            this.currentIndex22 = 3
            element.style.transition = 'transform 0.5s ease';
          }, 0);
        });

      }

    },
    turnright() {
      const elements = document.querySelectorAll('.background11');
      // this.currentIndex22 = (this.currentIndex22 + 1) % this.images.length;
      this.currentIndex22++;
      if (this.currentIndex22 >= this.images.length) {
        this.righttimer = setTimeout(() => {
          elements.forEach(element => {
            element.style.transition = 'none'
          })
          this.currentIndex22 = 0;

        }, 500);
      } else {
        elements.forEach(element => {
          element.style.transition = 'transform 0.5s ease';
        });
      }
      // this.currentIndex22 = (this.currentIndex22 + 1 + this.images.length) % this.images.length;
    },
    startAnimation() {
      this.animTimer = setInterval(() => {
        this.currentIndex22++;
        const elements = document.querySelectorAll('.background11');

        // 当currentIndex到达原数组长度时，重置为0
        elements.forEach(element => {
          element.style.transition = 'transform 0.5s ease';
        });
        if (this.currentIndex22 >= this.images.length) {
          setTimeout(() => {
            this.currentIndex22 = 0;
            // 遍历每个元素并设置 transition 属性为 none
            elements.forEach(element => {
              element.style.transition = 'none';
            });
          }, 500);
        }
      }, 3000); // 每3秒切换一次
    },
    handleSelect(key) {
     
console.log('导航栏',key)

      switch (+key) {
        case 1:
          this.$router.push('/')
          break;
        case 2:
          this.isDetailViews = false
          console.log(this.isDetailViews,'this.isDetailViews');
          break;
        case 3:
        // 点击行业应用
        
          break;
        case 4:
          this.$router.push('/aboutRY')
          break;
        case 5:

          break;

        default:
          break;
      }
    },
    setActive(index) {
      const carousel = this.$refs.myCarousel;
      this.productAllItemImg.forEach((item, i) => {
        item.isActive = (i === index);
      });
      this.centerCurrentIndex = index; // 更新当前索引
      carousel.setActiveItem(index)
    },
    getPageWidth() {
      const width = window.innerWidth;
      if (width > 768) {
        this.carouselheight = width * 0.239
      } else {
        this.carouselheight = width * 0.50427
      }
    }
  },
  computed: {
    newimg() {
      return [...this.images, this.images[0]]
    },
    carouselStyle() {
      return {
        height: this.carouselheight ? `${this.carouselheight}px` : '450px' // 如果有值，则设置高度，否则为 auto
      };
    },


  },
  mounted() {
    window.addEventListener('resize', this.getPageWidth);
    this.getPageWidth()
    // 创建 MediaQueryList 对象
    this.mediaQueryList = window.matchMedia("(max-width: 768px)");
    this.handleViewportChange(this.mediaQueryList);

    // 添加事件监听器
    this.mediaQueryList.addListener(this.handleViewportChange);
    setTimeout(() => {
      this.startAnimation()
    }, 100);
  },
  beforeDestroy() {
    // 移除事件监听器
    clearInterval(this.getPageWidthTimer);
    clearInterval(this.animTimer);
    this.mediaQueryList.removeListener(this.handleViewportChange);
  },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.eleDistrubTotal {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  /* max-width: 1920px; */
  width: 100%;
  padding: 0;
  margin: 0;
  .longHeader{
    width: 100%;
    max-height: 69px;
    background-color: #fff;
    display: flex;
      justify-content: center;
      align-content: center;
    position: fixed;
    top: 0px;
    z-index: 999;
.header {
    max-height: 69px;
    width: 62.5%;
    
    /* margin: 0 auto; */
    display: flex;
    justify-content: space-between;
    align-content: center;

    .left {
      max-width: 35%;

      display: flex;
      justify-content: space-between;
      align-content: center;

      .leftImg1 {
        max-width: 100%;
        height: auto;
        margin-right: 4%;
      }

      .leftImg2 {
        max-width: 100%;
        height: auto;
        margin: auto;
      }
    }

    .right {
      width: 46%;
    }
  }
  }
 

  .main {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    width: 100%;

    .trytry11 {
      margin-top: 69px;
      position: relative;
      width: 100%;
      /* padding-top: 23.4375%; */
      height: 450px;
      overflow: hidden;
      /* 隐藏超出部分 */

      .background11 {
        position: absolute;
        width: 100%;
        padding-top: 23.4375%;
        background-size: cover;
        /* 使背景图片覆盖整个盒子 */
        background-position: center;
        /* 背景居中 */
        background-repeat: no-repeat;
        /* 不重复背景图片 */
        transition: left 1s ease;

        /* 添加平滑的过渡效果 */
        .iconleft,
        .iconright {
          position: absolute;
          width: 3.333%;
          height: 14.2%;
          top: 50%;
          transform: translateY(-50%);
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
        }

        .iconleft {
          position: absolute;
          width: 3.333%;
          height: 14.2%;
          top: 50%;
          transform: translateY(-50%);
          left: 15%;
          background-image: url('../../src/assets/iconleft.png');
          background-size: cover;
          /* 使背景图片覆盖整个盒子 */
          background-position: center;
          /* 背景居中 */
          background-repeat: no-repeat;
          /* 不重复背景图片 */
        }

        .iconright {
          position: absolute;
          background-image: url('../../src/assets/iconright.png');
          background-size: cover;
          /* 使背景图片覆盖整个盒子 */
          background-position: center;
          /* 背景居中 */
          background-repeat: no-repeat;
          /* 不重复背景图片 */
          width: 3.333%;
          height: 14.2%;
          top: 50%;
          transform: translateY(-50%);
          right: 14%;
        }

        .turnbuttom {
          position: absolute;
          top: 90%;
          left: 50%;
          transform: translateX(-50%);
          width: 7.34%;
          height: 4%;
          display: flex !important;
          justify-content: space-evenly;
          align-items: center;
          background-color: transparent;

          .litlecircle {
            width: 13%;
            height: 100%;
            background-color: #ebebed;
            color: transparent;
            border-radius: 50%;
          }

          .isActiveHover {
            width: 20%;
            border-radius: 41%;
          }
        }
      }
    }

    .bodys {
      padding: 0;
        margin: 0 auto;
        width: 62.5%;
    :deep() .custom-tabs {
          display: flex;
          justify-content: space-between;
           flex-direction: column;
          /* 等分每个标签 */
        }
      
      
            .productAll {
              width: 100%;
              margin-top: 62px;
              display: flex;
              justify-content: flex-start;
              flex-direction: row;
              flex-wrap: wrap;
              align-content: space-between;
              align-items: flex-end;
              
              .productAllItem {
                width: 31.7%;
                height: 300px;
                margin-bottom: 65px;
                transition: box-shadow 0.3s;
                border-radius: 12px;
                  /* 添加过渡效果 */
                .productImg {
                  width: 100%;
                  height: 250px;
                  overflow: hidden;
                  /* 超出盒子的部分隐藏 */
                  background: #ebebed;
                  border-top-left-radius: 12px;
                  border-top-right-radius: 12px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
      
                }
      
                .responsive-image {
                  max-width: 100%;
                  /* 图片最大宽度为盒子宽度 */
                  height: auto;
                  /* 高度自动调整，保持比例 */
                }
      
                .productTitle {
                  font-size: 20px;
                  font-family: Microsoft YaHei, Microsoft YaHei-Regular;
                  font-weight: 400;
                  color: #ffffff;
                  line-height: 50px;
                  background-color: rgba(4, 103, 191, 0.80);
                  border-bottom-left-radius: 12px;
                  /* 下左角圆角 */
                  border-bottom-right-radius: 12px;
                  /* 下右角圆角 */
                }
              }
                            .productAllItem.active {
                              box-shadow: 1px 7px 18px 0px rgba(117, 117, 117, 0.59);
                              /* 添加阴影效果 */
                            }
      
            }
      :deep() .custom-tabs .el-tabs__header {
          width: 100%;
          /* 设置宽度为100% */
        }
             :deep() .custom-tabs .custom-label {
                  font-size: 22px;
                    font-family: "Alibaba PuHuiTi 2.0", "Alibaba PuHuiTi 2.0 -85 Bold";
                    font-weight: normal;
                    color: #4d4d4d;
                    line-height: 44px;
                  /* 根据需要调整字体大小 */
                }
        :deep() .custom-tabs  .el-tabs__nav-wrap::after{
          display: none;
        }
         :deep() .custom-tabs .is-top{
              width: 100%;
                    display: flex;
                      flex-direction: row;
                      flex-wrap: nowrap;
                      align-content: center;
                      justify-content: space-between;
            }
        :deep() .custom-tabs .el-tab-pane {
          /* 等分每个 tab */
          text-align: center;
          /* 居中对齐文本 */
        }
        :deep() .custom-tabs .el-tabs__nav-scroll{
          width: 100%;
        }
        /* 下划线底下那条线 */
      :deep() .custom-tabs .el-tabs__active-bar{
            height: 0;
                }
             :deep() .custom-tabs .el-tabs__item{
              display: flex;
             justify-content: center;
              background-color:#EBEBED;
              border-bottom: none;
              border-radius: 12px;
              font-size: 22px;
              font-family: "Alibaba PuHuiTi 2.0", "Alibaba PuHuiTi 2.0 -85 Bold";
                font-weight: normal;
                /* 去掉所有 tab 的下划线 */
                text-align: center;
              width: 18%;
              height: 64px;
                line-height: 64px;
              padding: 0;
             }
        :deep() .custom-tabs .el-tabs__item.is-active {
          background-color:#0467BF;
          border-radius: 12px;
          text-align: center;
          /* 被选中时的背景色 */
          color: white;
          /* 选中时的文字颜色 */
        }
      .bodyImg {
        margin: 8% 0;
          width: 26.58%;
          height: auto;
      }

      .centerTitle {
        display: flex;
        justify-content: space-between;
        align-content: space-between;
        margin-bottom: 55px;
      }

      .centerMessage {
        width:100%;
        font-size: 22px;
        text-align: left;
        color: #333333;
        line-height: 44px;
        text-indent: 2em;
        margin-top: 8%;
        margin-bottom: 15%;

        .Controlmessage {
          margin: 0;
        }
      }

      .centerImgVideo {
        width: 100%;
        height: 471px;
        background: #f7f7f7;
        display: flex;
        justify-content: center;
        align-items: center;

        .video {
          width: 100%;
          height: 471px;

        }

        .controlPart {
          width: 100%;
          display: flex;
          background: #fff;
          justify-content: space-between;
          align-items: center;

          .controlPartInner {
            width: 48%;
            height: 471px;
            background: #f7f7f7;
            border-radius: 12px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }

      }

    }
   .applicationandOther{
     width: 100%;
     display: flex;
     flex-direction: column;
     justify-content: center;
     align-items: center;

    
.applicationandOtherImg{
  width: 100%;
  height: auto;
  overflow: hidden;
  .andOtherImg{
    max-width: 100%;
      /* 图片最大宽度为盒子宽度 */
      height: auto;
  }
}

    }
        .single9Img{
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 90px 0 80px 0;
          .Part9{
            width: 955px;
            height: 516px;
            background-image: url('../../src/assets/productcenter_apply_LXJ.png');
            background-size: cover;
              /* 使背景图片覆盖整个盒子 */
              background-position: center;
              /* 背景居中 */
              background-repeat: no-repeat;
          }
        }
        .productParams {
          width: 62.3%;
          overflow: visible;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-content: center;
          position: relative;
    
          .productParamsTitle {
            display: flex;
            align-items: center;
            margin: 40px 0 46px 0;
            width: 100%;
    
            .carTitle {
              font-size: 38px;
              font-family: "Alibaba PuHuiTi 2.0", "Alibaba PuHuiTi 2.0 -85 Bold";
              font-weight: normal;
              color: #333333;
              line-height: 44px;
              letter-spacing: -0.76px;
            }
          }
    
          .leftIcon {
            width: 27px;
            height: 50px;
            position: absolute;
            top: 57%;
            left: -5%;
          }
    
          .rightIcon {
            width: 27px;
            height: 50px;
            position: absolute;
            top: 57%;
            right: -5%;
          }
          .productAlPart{
            overflow: hidden;
.productAll {
    z-index: 1;
    width: 350%;
    margin-top: 62px;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: space-between;
    align-items: flex-end;
    transition: transform 0.3s ease;
      /* 添加平滑过渡 */
    /* padding-left: 1.5%; */
    /* overflow: hidden; */

    .productAllItem {
      width: 32%;
      height: 300px;
      margin: 0 23px 58px 23px;
      transition: box-shadow 0.3s;
      border-radius: 12px;
  /* 添加过渡效果 */
 
      .productImg {
        width: 100%;
        height: 250px;
        overflow: hidden;
        /* 超出盒子的部分隐藏 */
        background: #ebebed;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        display: flex;
        justify-content: center;
        align-items: center;

      }

      .responsive-image {
        max-width: 100%;
        /* 图片最大宽度为盒子宽度 */
        height: auto;
        /* 高度自动调整，保持比例 */
      }

      .productTitle {
        font-size: 20px;
        font-family: Microsoft YaHei, Microsoft YaHei-Regular;
        font-weight: 400;
        color: #ffffff;
        line-height: 50px;
        background-color: rgba(4, 103, 191, 0.80);
        border-bottom-left-radius: 12px;
        /* 下左角圆角 */
        border-bottom-right-radius: 12px;
        /* 下右角圆角 */
      }
    }
 .productAllItem.active {
  box-shadow: 1px 7px 18px 0px rgba(117, 117, 117, 0.59);
   /* 添加阴影效果 */
 }
 .productAllItem.clickActive {
   box-shadow: 1px 7px 20px 5px rgba(114, 114, 114, 0.59);
   /* 添加阴影效果 */
 }

  }
          }
          
    
        }
  }

    footer {
      width: 100%;
      height: 472px;
      background-color: #656569;
      display: flex;
      justify-content: center;
      align-items: center;
      /* padding:112px 390px 106px 368px; */
      box-sizing: border-box;
  
      .footerPart {
        width: 62.5%;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
  
        .footleft {
          width: 65%;
          display: flex;
          align-items: flex-start;
          flex-direction: column;
          color: #FFFFFF;
          font-size: 14px;
  
          .leftertop {
            display: flex;
            justify-content: space-between;
            width: 100%;
            text-align: left;
            line-height: 40px;
            letter-spacing: 1px;
            color: #FFFFFF;
            font-size: 14px;
  
            ul {
              list-style: none;
              /* 去掉默认的列表样式 */
              padding: 0;
              /* 去掉内边距 */
              margin: 0;
              /* 去掉外边距 */
            }
  
            li {
              list-style-type: none;
              /* 默认隐藏所有 <li> */
            }
  
            li:first-child {
              list-style-type: disc;
              /* 显示每个 <ul> 的第一个 <li> 并保留项目符号 */
              margin-bottom: 20px;
            }
  
            /* 设置第一个 li 的标记颜色为红色 */
            li:first-child::marker {
              color: #F5A300;
              /* 将标记颜色设置为红色 */
            }
          }
  
          .footleftbottom {
            margin-top: 10%;
          }
        }
  
        .footright {
          width: 30%;
          /* height: 200px; */
          display: flex;
          justify-content: space-between;
          align-content: space-between;
          flex-direction: column;
  
          .footNumber {
            /* margin-top: 20px; */
            font-size: 12px;
            color: #FFFFFF;
  
            .citymargin {
              margin: 10px 20px;
            }
  
            .footNumberspan1 {
              display: flex;
              justify-content: flex-end;
              align-items: center;
              flex-direction: row;
            }
  
            .footNumberspan2 {
              display: flex;
              justify-content: flex-end;
              align-items: center;
              flex-direction: row;
            }
          }
  
          .footCode {
            width: 100%;
            height: 98px;
            margin-top: 10%;
            background-position: right;
            /* 背景居中 */
  
            background-repeat: no-repeat;
            background-image: url('../../src/assets/qrCode.png');
          }
        }
      }
  
  
    }
}
</style>