import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import * as echarts from 'echarts'
import './utils/flexible'
import '@/assets/iconfont/iconfont.css'
import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn'
// import { message } from './lib/resetMessage'
import { Message } from 'element-ui'

dayjs.locale('zh-cn')
Vue.use(dayjs)
Vue.use(ElementUI)
Vue.use(echarts);
Vue.prototype.$dayjs = dayjs
Vue.prototype.$message = Message
Vue.prototype.$echarts=echarts
dayjs.suppressDeprecationWarnings = true
Vue.config.productionTip = false
Vue.prototype.globalClick = function (callback) {
  document.getElementById('main').onclick = function () {
    callback()
  }
}
// 创建事件总线实例
const bus = new Vue()
// 将事件总线实例挂载到Vue原型上，使所有组件都可以访问
Vue.prototype.$bus = bus
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
